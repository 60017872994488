import { PageProps } from "gatsby";
import * as React from "react";
import Shell from "../../layout/shell";

const PrivacyPolicy: React.FC<PageProps> = (pageProps) => {
  return (
    <Shell>
      <div className="fixed-width">
        <h1>Privacy policy</h1>
      </div>
      <hr />
      <div className="fixed-width">
        <p className="general-copy">
          This Privacy Policy outlines how we protect your personal data that we
          collect and process, in accordance with data protection legislation,
          including the General Data Protection Regulation (GDPR). The policy
          explains how we do this and tells you about your privacy rights and
          how the law protects you.
          <br />
          <br />
          <br />
          HOP is the trading name for QuestPit Ltd which is registered in
          England & Wales under number 09533916 . The registered office of the
          company is 7th Floor Aldgate House, Aldgate High Street, London,
          England, EC3N 1AG
          <br />
          <br />
          <h3>WHAT PERSONAL INFORMATION DO WE COLLECT?</h3>
          Mostly, the personal data we collect and process is limited to the
          following types:
          <br />
          <br />
          Work/personal address
          <br />
          <br />
          <h3>WHY DO WE USE YOUR PERSONAL DATA?</h3>
          We will generally only use your personal data for the purpose
          described to you at the time of collection. Data protection
          legislation specifies that we must have one or more of these reasons
          for processing personal data:
          <br />
          <br />
          To fulfil a contract we have with you or your employer, or take steps
          to enter into a contract with you or your employer
          <br />
          <br />
          When it’s our legal duty (e.g. in relation to supervisory authority
          investigations or fraud prevention)
          <br />
          <br />
          When it’s in our legitimate interest (e.g. connected to expanding our
          business network, ensuring our clients receive relevant advertisements
          or recruitment purposes)
          <br />
          <br />
          When you consent to it (e.g. through newsletter sign-ups)
          <br />
          <br />
          <br />
          <h3>HOW LONG WILL WE KEEP YOUR PERSONAL DATA?</h3>
          We will keep your personal data as necessary for the purpose for which
          it was collected, and up to two years after last contact.
          <br />
          <br />
          <h3>WHAT HAPPENS IF YOU CHOOSE NOT TO GIVE US YOUR PERSONAL DATA?</h3>
          We may need to collect personal data by law, or under the terms of a
          contract we have with you. If you choose not to give us this personal
          data, it may delay or prevent us from providing our products or
          services to you or prevent us from using your services.
          <br />
          <br />
          <h3>WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</h3>
          We may share your personal data with our contractors and service
          providers who perform certain business-related functions. These
          companies may include:
          <br />
          <br />
          Third-party marketing and advertising agencies
          <br />
          <br />
          Database service providers, including those providing cloud storage
          <br />
          <br />
          Backup and disaster recovery service providers
          <br />
          <br />
          Website hosting companies
          <br />
          <br />
          Email/mailing service providers
          <br />
          <br />
          Recruitment platform
          <br />
          <br />
          Recruitment & selection agencies
          <br />
          <br />
          IT-service providers
          <br />
          <br />
          We may also need to disclose your personal data to public authorities
          where required to do so by law in order to, for example, respond to a
          request from a law enforcement authority, a court or a government
          agency (including in response to public authorities to meet national
          security or law enforcement requirements).
          <br />
          <br />
          To safeguard your personal data, we require third-parties receiving
          personal data from us to commit to respecting a similar level of the
          protection of personal data to that under the GDPR in their contracts
          with us. If we transfer information outside of the European Economic
          Area (EEA), we will make sure that it is protected in the same way as
          if it was being used in the EEA. To do this, we will use one or more
          of these safeguards:
          <br />
          <br />
          Only transfer it to a non-EEA country with privacy laws that give the
          same protection as the EEA, as deemed by the European Commission.
          <br />
          <br />
          Ensure that a contract with the recipient is in place that means they
          must protect it to the same standards as the EEA, such as through
          certification to the EU-US Privacy Shield or signed EU-approved
          Standard Contractual Clauses with us.
          <br />
          <br />
          <br />
          <h3>
            DO YOU WANT US TO SHARE WHAT PERSONAL DATA WE HAVE ABOUT YOU, WITH
            YOU?
          </h3>
          If you want us to share information that we’re obliged to share about
          you, contact us using one of the methods in the get in touch section.
          <br />
          <br />
          <h3>IS THE PERSONAL DATA WE HAVE ABOUT YOU INCORRECT?</h3>
          You have the right to question any information we have about you that
          you think is wrong or incomplete. Please contact us using one of the
          methods in the get in touch section. If you do, we will take
          reasonable steps to check its accuracy and correct it.
          <br />
          <br />
          <h3>DO YOU WANT US TO STOP USING YOUR PERSONAL DATA?</h3>
          You have the right to object to certain ways that we use your personal
          data, or to ask us to restrict or stop the processing of, delete,
          remove, or stop using your personal data if there is no need for us to
          keep it. These are known as the ‘right to restrict’, ‘right to object’
          and the ‘right to erasure’, or the ‘right to be forgotten’
          respectively. In addition, you also have the right to data
          portability. This means that you can request a copy of your personal
          data for the purpose of transferring it to another data controller.
          <br />
          <br />
          In some cases, there will be legitimate, legal, or other official
          reasons for us to keep your data. But you have the right to contact us
          using one of the methods in the get in touch section to request us to
          stop using your data.
          <br />
          <br />
          If you exercise one of your data subject rights mentioned above,
          please note that we will need to verify your identity as to not
          provide your personal data to the wrong person.
          <br />
          <br />
          <h3>SECURITY OF YOUR INFORMATION</h3>
          To help protect the privacy of data and personally identifiable
          information you transmit through use of Yondr’s website, we maintain
          physical, technical and administrative safeguards. We update and test
          our security technology on an on-going basis. We restrict access to
          your personal data to those employees who need to know that
          information to provide benefits or services to you. In addition, we
          train our employees about the importance of confidentiality and
          maintaining the privacy and security of your information. We commit to
          taking appropriate disciplinary measures to enforce our employees’
          privacy responsibilities.
          <br />
          <br />
          <h3>DATA STORAGE</h3>
          Your personal data is stored by Home of Production on its servers, and
          on the servers of the third-party service providers Yondr engages,
          which are located within the European Union (EU) and in the United
          Kingdom. Home of Production retains data for different durations
          depending on the purposes for data collection and use.
          <br />
          <br />
          <h3>HOW TO CONTACT US</h3>
          If you have any questions, comments, complaints or want more details
          about how we use your personal data, please let us know. We do our
          best to be transparent, protect your personal data and offer you as
          much control as we can.
          <br />
          <br />
          <h3>CHANGES TO HOME OF PRODUCTION PRIVACY POLICY</h3>
          We regularly review and, where necessary, update our privacy
          statement. We reserve our right to amend and update this privacy
          statement without notice at any time. The most recent version will be
          available on our website.
        </p>
      </div>
    </Shell>
  );
};

export default PrivacyPolicy;
